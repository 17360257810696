import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  WorkshopService,
  IPFilterWorkshop,
  WorkshopPublicDetail,
  WorkshopPublicBasicInfo,
  IWorkshopMap,
} from "../../models";

const WorkshopAPI = {
  getById: (
    id: number,
    params: { coordinates: string; userTimeZone: string }
  ): Promise<AxiosResponse<WorkshopPublicDetail>> =>
    requests.get(`/publicInfo/workshops/${id}`, params),
  simpleGetById: (
    id: number,
    params: { coordinates: string; userTimeZone: string }
  ): Promise<AxiosResponse<WorkshopPublicDetail>> =>
    requests.get(`/publicInfo/workshopDetails/${id}`, params),
  get: (
    filters?: IPFilterWorkshop
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops`, filters),
  getWarranties: (id: number | string): Promise<AxiosResponse<any>> =>
    requests.get(`workshop/${id}/warranty`),
  search: (
    filters?: IPFilterWorkshop
  ): Promise<AxiosResponse<WorkshopPublicBasicInfo[]>> =>
    requests.get(`/publicInfo/workshops/search`, filters),
  getNavigationUrl: (workshopId: number): Promise<AxiosResponse<string>> =>
    requests.get(`/getNavigationUrl/${workshopId}`),
  getWorkshopById: (id: number): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/workshop/${id}/services`),
  getServicesTypes: (id: number): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/workshop/${id}/servicesTypes`),
  getByPackage: (filters: any): Promise<AxiosResponse<any[]>> =>
    requests.get(`/publicInfo/workshops/servicepackage/search`, filters),
  getWorkshopAverageResponseTime: (
    workshopId: number
  ): Promise<AxiosResponse<string>> =>
    requests.get(`/publicInfo/workshops/responseTime/${workshopId}`),

  getWorkshopByDistance: (payload: {
    coordinates: string;
    searchCoordinates: string;
    searchRange: number;
    serviceTypeCodeList?: string;
    packageList?: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(
      `publicInfo/workshops/searchbydistance?coordinates=${
        payload.coordinates
      }&searchCoordinates=${payload.searchCoordinates}&searchRange=${
        payload.searchRange
      }${
        payload.serviceTypeCodeList
          ? "&serviceTypeCodeList=" + payload.serviceTypeCodeList
          : ""
      }${payload.packageList ? "&packageList=" + payload.packageList : ""}`
    ),
  getWorkshopByCoordinates: (payload: {
    coordinates: string;
    searchCoordinates: string;
    searchRange: number;
    serviceTypeCodeList?: string;
    packageList?: string;
    workshopFranchise?: string;
    workshopName?: string;
    workshopBrands?: string;
    additionalInfoCodes?: string;
  }): Promise<AxiosResponse<IWorkshopMap[]>> =>
    requests.get(
      `publicInfo/workshops/searchbyCoordinates?coordinates=${
        payload.coordinates
      }&searchCoordinates=${payload.searchCoordinates}&searchRange=${
        payload.searchRange
      }${
        payload.serviceTypeCodeList
          ? "&serviceTypeCodeList=" + payload.serviceTypeCodeList
          : ""
      }${payload.packageList ? "&packageList=" + payload.packageList : ""}${
        payload.additionalInfoCodes
          ? "&additionalInfoCodes=" + payload.additionalInfoCodes
          : ""
      }${
        payload.workshopBrands
          ? "&workshopBrands=" + payload.workshopBrands
          : ""
      }${payload.workshopName ? "&workshopName=" + payload.workshopName : ""}${
        payload.workshopFranchise
          ? "&workshopFranchise=" + payload.workshopFranchise
          : ""
      }`
    ),
  getWorkshopByDistancePaginated: (payload: {
    workshopFranchise?: string;
    workshopName?: string;
    workshopBrands?: string;
    additionalInfoCodes?: string;
    coordinates: string;
    searchCoordinates: string;
    searchRange: number;
    serviceTypeCodeList: string;
    packageList: string;
    page: number;
    pageSize: number;
    sortField: string;
    hasWarranty?: boolean;
  }): Promise<AxiosResponse<any>> =>
    /* requests.get(`publicInfo/workshops/searchbyCoordinatesInfo?${urlize(payload)}`) */
    requests.get(
      `publicInfo/workshops/searchbyCoordinatesInfo?coordinates=${
        payload.coordinates
      }&searchCoordinates=${payload.searchCoordinates}&searchRange=${
        payload.searchRange
      }${
        payload.serviceTypeCodeList
          ? "&serviceTypeCodeList=" + payload.serviceTypeCodeList
          : ""
      }${
        payload.packageList ? "&packageList=" + payload.packageList : ""
      }&sortField=${payload.sortField}&page=${payload.page}&pageSize=${
        payload.pageSize
      }${
        payload.additionalInfoCodes
          ? "&additionalInfoCodes=" + payload.additionalInfoCodes
          : ""
      }${
        payload.workshopBrands
          ? "&workshopBrands=" + payload.workshopBrands
          : ""
      }${payload.workshopName ? "&workshopName=" + payload.workshopName : ""}${
        payload.workshopFranchise
          ? "&workshopFranchise=" + payload.workshopFranchise
          : ""
      }`
      // ${payload.hasWarranty ? `&hasWarranty=${payload.hasWarranty}` : ""} // Enable after warranty functionality is implemented
    ),
  getWorkshopHighlight: (
    cityName?: string,
    municipalityZone?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `workshop/Highlights?${
        cityName ? `cityName=${encodeURIComponent(cityName)}` : ""
      }${
        municipalityZone
          ? `&MunicipalityZone=${encodeURIComponent(municipalityZone)}`
          : ""
      }`
    ),
};

export default WorkshopAPI;
