import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

axios.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {}
  return config;
});

/**
 * `requests` is an object that encapsulates methods for making HTTP requests using Axios.
 * Each method corresponds to a specific HTTP verb and is designed to be used for API calls.
 */
export const requests = {
  /**
   * Performs a GET request to the specified URL.
   * @param {string} url - The URL to send the GET request to.
   * @param {any} [params] - Optional parameters to be sent with the request.
   * @returns {Promise} - A promise that resolves with the response of the GET request.
   */
  get: (url: string, params?: any) => axios.get(url, { params }),

  /**
   * Performs a POST request to the specified URL with the provided body.
   * @param {string} url - The URL to send the POST request to.
   * @param {Object} body - The body of the POST request.
   * @returns {Promise} - A promise that resolves with the response of the POST request.
   */
  post: (url: string, body: {}) => axios.post(url, body),

  /**
   * Performs a PUT request to the specified URL with the provided body and optional configuration.
   * @param {string} url - The URL to send the PUT request to.
   * @param {Object} body - The body of the PUT request.
   * @param {Object} [config={}] - Optional configuration for the PUT request.
   * @returns {Promise} - A promise that resolves with the response of the PUT request.
   */
  put: (url: string, body: {}, config: {} = {}) => axios.put(url, body, config),

  /**
   * Performs a DELETE request to the specified URL.
   * @param {string} url - The URL to send the DELETE request to.
   * @returns {Promise} - A promise that resolves with the response of the DELETE request.
   */
  del: (url: string) => axios.delete(url),

  /**
   * Performs a PATCH request to the specified URL with the provided body.
   * @param {string} url - The URL to send the PATCH request to.
   * @param {Object} body - The body of the PATCH request.
   * @returns {Promise} - A promise that resolves with the response of the PATCH request.
   */
  patch: (url: string, body: {}) => axios.patch(url, body),
};
