import { AxiosResponse } from "axios";
import { requests } from "./";
import OTPCache from "./OTPCache";
import {
  RatingDetail,
  VehicleMainPurpose,
  VehicleCatalog,
  IMunicipalityZone,
  IServiceCategory,
  IPackageCategory
} from "../../models";

const AxiosEmptyResponseOTP = {
  status: 200,
  statusText: "OK",
  headers: {},
  config: {},
};

const OTPTreeObj = new OTPCache();

const CatalogAPI = {
  getVehicleMainPurposes: async (
    language: string
  ): Promise<AxiosResponse<VehicleMainPurpose[]>> =>
    requests.get(`/catalogs/vehicleMainPurposes?language=${language}`),

  getDays: (language: string = "ES-MX"): Promise<AxiosResponse<[]>> =>
    requests.get(`/catalogs/days?language=${language}`),
  getServiceType: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/serviceTypes?language=${language}`),

  getWorkshopPackages: async (
    workshopId: string,
    includeEmpty: boolean = true
  ): Promise<AxiosResponse<any[]>> =>
    requests.get(`/servicepackages/${workshopId}?includeEmpty=${includeEmpty}`),
  getPackagesDetail: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/catalogs/servicepackages`),

  getRatingDetails: (
    language: string
  ): Promise<AxiosResponse<RatingDetail[]>> =>
    requests.get(`/catalogs/endConsumerFeedbackCategory?language=${language}`),

  getVehicleTypes: async (
    language: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> => {
    const otpKey = language;
    if (OTPTreeObj.getVehicleTypes(otpKey))
      return {
        ...AxiosEmptyResponseOTP,
        data: OTPTreeObj.getVehicleTypes(otpKey)!,
      };
    const request = await requests.get(
      `/catalogs/vehicleTypes?language=${language}`
    );
    OTPTreeObj.setVehicleTypes(otpKey, request.data);
    return request;
  },

  getVehicleBrands: async (
    language: string,
    vehicleTypeCode: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> => {
    if (vehicleTypeCode) {
      const otpKey = `${language}_${vehicleTypeCode}`;
      if (OTPTreeObj.getVehicleBrands(otpKey)) {
        return {
          ...AxiosEmptyResponseOTP,
          data: OTPTreeObj.getVehicleBrands(otpKey)!,
        };
      }
      const request = await requests.get(
        `/catalogs/vehicleBrands?vehicleTypeCode=${vehicleTypeCode}&language=${language}`
      );
      OTPTreeObj.setVehicleBrands(otpKey, request.data);
      OTPTreeObj.revalidate("vehicleBrands");
      return request;
    }
  },

  getVehicleBrandYears: async (
    language: string,
    vehicleTypeCode: string,
    vehicleBrandCode: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> => {
    if (vehicleTypeCode && vehicleBrandCode) {
      const otpKey = `${language}_${vehicleTypeCode}_${vehicleBrandCode}`;
      if (OTPTreeObj.getVehicleBrandYears(otpKey))
        return {
          ...AxiosEmptyResponseOTP,
          data: OTPTreeObj.getVehicleBrandYears(otpKey)!,
        };
      const request = await requests.get(
        `/catalogs/vehicleBrandYears?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleBrandCode=${vehicleBrandCode}`
      );
      OTPTreeObj.setVehicleBrandYears(otpKey, request.data);
      OTPTreeObj.revalidate("vehicleBrandYears");
      return request;
    }
  },

  getVehicleModels: async (
    language: string,
    vehicleTypeCode: string,
    vehicleYearCode: string,
    vehicleBrandCode?: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> => {
    if (vehicleTypeCode && vehicleYearCode && vehicleBrandCode) {
      const otpKey = `${language}_${vehicleTypeCode}_${vehicleBrandCode}_${vehicleYearCode}`;
      if (OTPTreeObj.getVehicleModels(otpKey))
        return {
          ...AxiosEmptyResponseOTP,
          data: OTPTreeObj.getVehicleModels(otpKey)!,
        };
      const request = await requests.get(
        `/catalogs/vehicleModels?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleYearCode=${vehicleYearCode}${
          vehicleBrandCode ? `&vehicleBrandCode=${vehicleBrandCode}` : ""
        }`
      );
      OTPTreeObj.setVehicleModels(otpKey, request.data);
      OTPTreeObj.revalidate("vehicleModels");
      return request;
    }
  },

  getVehicleFuelTypes: async (
    language: string,
    vehicleTypeCode: string,
    vehicleModelCode: string,
    vehicleBrandCode?: string,
    vehicleYearCode?: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    vehicleTypeCode && vehicleModelCode && vehicleBrandCode && vehicleYearCode
      ? requests.get(
          `/catalogs/vehicleFuelTypes?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleModelCode=${vehicleModelCode}${
            vehicleBrandCode ? `&vehicleBrandCode=${vehicleBrandCode}` : ""
          }${vehicleYearCode ? `&vehicleYearCode=${vehicleYearCode}` : ""}`
        )
      : null,

  getVehicleDisplacements: async (
    language: string,
    vehicleTypeCode: string,
    vehicleFuelTypeCode: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    vehicleTypeCode && vehicleFuelTypeCode
      ? requests.get(
          `/catalogs/vehicleDisplacements?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleFuelTypeCode=${vehicleFuelTypeCode}`
        )
      : null,

  getVehicleEquipments: async (
    language: string,
    vehicleTypeCode: string,
    vehicleDisplacementCode: string
  ): Promise<AxiosResponse<VehicleCatalog[]>> =>
    vehicleTypeCode && vehicleDisplacementCode
      ? requests.get(
          `/catalogs/vehicleEquipments?language=${language}&vehicleTypeCode=${vehicleTypeCode}&vehicleDisplacementCode=${vehicleDisplacementCode}`
        )
      : null,

  // Functions merged from catalog API
  fetchServicesCarousel: (
    language: string
  ): Promise<AxiosResponse<IServiceCategory[]>> => {
    return requests.get(`catalogs/serviceTypesCarousel?language=${language}`);
  },
  fetchPackagesCombination: (
    language: string
  ): Promise<AxiosResponse<IPackageCategory[]>> => {
    return requests.get(
      `catalogs/servicepackagecombinations?language=${language}`
    );
  },
  getMunicipalityZones: (): Promise<AxiosResponse<IMunicipalityZone[]>> => {
    return requests.get(
      `catalogs/MunicipalityZones`
    );
  },
  /**
   * Unused but relevant code
   */
  /* 
  getVehicleLicensePlateStates: async (
    language: string
  ): Promise<AxiosResponse<VehicleLicensePlateState[]>> =>
    requests.get(`/catalogs/vehicleLicensePlateStates?language=${language}`),
  getWorkshopTypes: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<WorkshopType[]>> =>
    requests.get(`/catalogs/workshopTypes?language=${language}`),
  getWorkshopServicesType: async (
    language: string = "ES-MX",
    serviceTypeCode?: string
  ): Promise<AxiosResponse<ServiceCategory[]>> =>
    requests.get(
      `/catalogs/serviceCategories?language=${language}${
        serviceTypeCode ? "&serviceTypeCodes=" + serviceTypeCode : ""
      }`
    ),
  getServices: async (
    language: string = "ES-MX"
  ): Promise<AxiosResponse<WorkshopService[]>> =>
    requests.get(`/catalogs/services?language=${language}`),
  getPackages: async (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/servicepackages`),
  getVehicleAccesories: async (language: string): Promise<AxiosResponse<any[]>> => requests.get(`/catalogs/vehicleAccesories?${language}`),
  fetchServiceByWorkshopType: (language: string, workshopTypeCode: string): Promise<AxiosResponse<Service[]>> => {
    return requests.get(
      `catalogs/WorkshopTypeServices?language=${language}&workshopTypeCode=${workshopTypeCode}`,
    );
  },
  fetchPackagesCarousel: (language: string): Promise<AxiosResponse<IServiceCarousel[]>> => {
    return requests.get(`catalogs/servicepackagenames?language=${language}`);
  }, 
  fetchPackageDetail: (
    language: string,
    servicePackageCode: string
  ): Promise<AxiosResponse<IPackageDetail[]>> => {
    return requests.get(
      `catalogs/servicepackage/${servicePackageCode}/detail?language=${language}`
    );
  },
  fetchAppointmentStatuses: (
    language: string
  ): Promise<AxiosResponse<AppointmentStatus[]>> => {
    return requests.get(`catalogs/appointmentStatus?language=${language}`);
  },
  */
 
};

export default CatalogAPI;
