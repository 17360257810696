import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  IcampaignRecord
} from "../../models";

const CampaignsAPI = {
  set: (
    record: IcampaignRecord
  ): Promise<AxiosResponse<IcampaignRecord>> =>
    requests.post(`/promoCodes/saveCampaignTracking`, record),
  update: (params: {
    campaignTrackingId: number | string;
    endConsumerId: number | string;
    endConsumerEmail: string;
  }): Promise<AxiosResponse<IcampaignRecord>> =>
    requests.put(`/promoCodes/updateCampaignTracking?campaignTrackingId=${params.campaignTrackingId}&endConsumerId=${params.endConsumerId}&endConsumerEmail=${params.endConsumerEmail}`, params),
};

export default CampaignsAPI;
