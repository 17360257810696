import { AxiosResponse } from "axios";
import { requests } from "./";

const EndConsumerRequestAPI = {
  addRequest: (request: any): Promise<AxiosResponse<any>> =>
    requests.post("/EndConsumerRequest", request),
  getShortRequests: async (
    userId: string,
    // dateFrom?: string,
    page: number,
    pageSize: number,
    sortDesc: boolean,
    sortNewMessage: boolean,
    filterWorkshop?: string,
    filterCar?: string
    // dateTo?: string,
    // status?: string,
    // type?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/EndConsumerRequestSummary?userId=${userId}&pageSize=${pageSize}&page=${page}&sortDesc=${sortDesc}&sortNewMessage=${sortNewMessage}${
        filterWorkshop ? `&filterWorkshop=${filterWorkshop}` : ""
      }${filterCar ? `&filterCar=${filterCar}` : ""}`
    ),
  getEntryRequest: async (requestId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/WorkshopRequestMessages?RequestId=${requestId}`),
  getEntryRequestByAppointmentId: async (appointmentId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/${appointmentId}/EndConsumerRequests`),
    
  message: async (payload: any): Promise<AxiosResponse<string>> =>
    requests.post(`/Message`, payload),
  
  markAsSeen: async (MessageId: string): Promise<AxiosResponse<string>> =>
    requests.put(`/EndConsumerRequest/Messages/${MessageId}`, {}),
  
  getWorkshopsToRequest: async (userId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/GetEndConsumerRequestWorkshops?userId=${userId}`),
  getVehiclesToRequest: async (userId: string): Promise<AxiosResponse<any>> =>
    requests.get(`/GetEndConsumerRequestVehicles?userId=${userId}`)
  /**
   * Unused code but relevant for the project
   */
  /* 
  getRequests: async (
    userId: string,
    dateFrom?: string,
    dateTo?: string,
    status?: string,
    type?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/EndConsumerRequests?userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}${
        status ? "&requestStatus=" + status : ""
      }${type ? "&requestType=" + type : ""}`
    ), 
  getMultiRequests: async (
    userId: string,
    dateFrom?: string,
    dateTo?: string,
    status?: string,
    type?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/EndConsumerMultiRequests?userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}${
        status ? "&requestStatus=" + status : ""
      }${type ? "&requestType=" + type : ""}`
    ),
  closeRequest: async (payload: any): Promise<AxiosResponse<string>> =>
    requests.put(`/closeRequest`, payload),
  addMultiRequest: (
    request: any,
    top: number,
    services: string,
    wsId: string,
    coordinates?: string,
    searchCoordinates?: string,
    searchRange?: string,
    userTimeZone?: string,
    pack?: string
  ): Promise<AxiosResponse<any>> =>
    requests.post(
      `/EndConsumerMultiRequest?top=${top}&services=${services}&workshopId=${wsId}&coordinates=${coordinates}&searchCoordinates=${searchCoordinates}&searchRange=${searchRange}&userTimeZone=${userTimeZone}&packages=${pack}`,
      request
    ),
  */
};

export default EndConsumerRequestAPI;
