import OtpTreeCache, { OTP_EXPIRE_DICT } from "../../assets/OtpTreeCache";

interface OTPObj {
  code: string;
  desc: string;
}
interface OTPModelObj extends OTPObj {
  brandCode: string;
}
type OTPTreeObj = {
  vehicleTypes: {
    [language: string]: OTPObj[];
  };
  vehicleBrands: {
    [vehicleTypeCode: string]: OTPObj[];
  };
  vehicleBrandYears: {
    [language_vehicleTypeCode_vehicleBrandCode: string]: OTPObj[];
  };
  vehicleModels: {
    [language_vehicleTypeCode_vehicleYearCode: string]: OTPModelObj[];
  };
  expire: {
    // 1650563961356 { Date.now() + EXTRATIMEFORKEY when push this new hard-coded Obj }
    vehicleBrands: number; // EXTRATIMEFORKEY: 3 MONTHS
    vehicleBrandYears: number; // EXTRATIMEFORKEY: 1 MONTH
    vehicleModels: number; // EXTRATIMEFORKEY: 2 WEEKS
  };
  cache: string; // "refetch" | "expire"
};

class OTPCache {
  private cache?: OTPTreeObj;
  constructor() {
    this.cache = this.getObject() ?? undefined;
  }
  setObject() {
    localStorage.setItem("otp_tree", JSON.stringify(this.getObject()));
  }
  generate(): OTPTreeObj {
    const obj_: OTPTreeObj = OtpTreeCache;
    localStorage.setItem("otp_tree", JSON.stringify(obj_));
    this.cache = obj_;
    return obj_;
  }
  getObject(): OTPTreeObj {
    if (this.cache) return this.cache;
    const obj = localStorage.getItem("otp_tree");
    if (!obj) {
      return this.generate();
    }
    try {
      this.cache = JSON.parse(obj);
    } catch (e) {
      this.generate();
    }
    return this.cache!;
  }

  getValidPseudoObject(
    dict: "vehicleModels",
    key: string
  ): null | OTPModelObj[];
  getValidPseudoObject(
    dict: "vehicleBrandYears" | "vehicleBrands" | "vehicleTypes",
    key: string
  ): null | OTPObj[];
  getValidPseudoObject(
    dict:
      | "vehicleBrandYears"
      | "vehicleBrands"
      | "vehicleModels"
      | "vehicleTypes",
    key: string
  ): null | OTPObj[] | OTPModelObj[] {
    if (this.cache![dict] && key in this.cache![dict])
      return this.cache![dict][key];
    this.generate();
    return key in this.cache![dict] ? this.cache![dict][key] : null;
  }
  needRevalidate(
    dict: "vehicleBrandYears" | "vehicleBrands" | "vehicleModels"
  ): boolean {
    if (!this.cache!.expire || !this.cache!.expire[dict]) this.generate();
    return this.cache!.expire[dict] < Date.now();
  }
  revalidate(dict: "vehicleBrandYears" | "vehicleBrands" | "vehicleModels") {
    this.cache!.expire[dict] = Date.now() + OTP_EXPIRE_DICT[dict];
    this.setObject();
  }
  setVehicleTypes(key: string, value: OTPObj[]) {
    if (this.cache) {
      this.cache.vehicleTypes[key] = value;
      this.setObject();
    }
    return null;
  }
  getVehicleTypes(key: string): OTPObj[] | null {
    if (this.cache) {
      return this.getValidPseudoObject("vehicleTypes", key);
    }
    return null;
  }
  setVehicleBrands(key: string, value: OTPObj[]) {
    if (this.cache) {
      this.cache.vehicleBrands[key] = value;
      this.setObject();
    }
    return null;
  }
  getVehicleBrands(key: string): OTPObj[] | null {
    if (this.cache && !this.needRevalidate("vehicleBrands")) {
      return this.getValidPseudoObject("vehicleBrands", key);
    }
    return null;
  }
  setVehicleBrandYears(key: string, value: OTPObj[]) {
    if (this.cache) {
      this.cache.vehicleBrandYears[key] = value;
      this.setObject();
    }
    return null;
  }
  getVehicleBrandYears(key: string): OTPObj[] | null {
    if (this.cache && !this.needRevalidate("vehicleBrandYears")) {
      return this.getValidPseudoObject("vehicleBrandYears", key);
    }
    return null;
  }
  setVehicleModels(key: string, value: OTPModelObj[]) {
    if (this.cache) {
      this.cache.vehicleModels[key] = value;
      this.setObject();
    }
    return null;
  }
  getVehicleModels(key: string): OTPModelObj[] | null {
    if (this.cache && !this.needRevalidate("vehicleModels")) {
      return this.getValidPseudoObject("vehicleModels", key);
    }
    return null;
  }
}

export default OTPCache;
