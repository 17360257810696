import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  Balance,
  RedeemPetition,
  RedeemResponse,
  Reward
} from "../../models/Promocodes";

const PromoCodesAPI = {
  getRewards: async (): Promise<AxiosResponse<Reward[]>> =>
    requests.get("/promoCodes/rewards"),
  getUserBalance: async (userId: number): Promise<AxiosResponse<Balance>> =>
    requests.get(`/promoCodes/balance/${userId}`),
  redeemReward: async (
    redeem: RedeemPetition
  ): Promise<AxiosResponse<RedeemResponse>> =>
    requests.post("/promoCodes/redeem", redeem),
  redeemNotification: async (redeem: {
    email: string;
    prizeRedeemedCode: string;
  }): Promise<AxiosResponse<boolean>> =>
    requests.post("/promoCodes/redeemnotification", redeem),
  getInvitationCode: async (invitation: {
    userReferrerId: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/code", invitation),
  inviteUser: async (invitation: {
    senderUserId: number;
    email: string;
  }): Promise<AxiosResponse<string>> =>
    requests.post("/promoCodes/invitations", invitation),
  checkUserInvite: async (user: {
    userInvitedID: number;
    userInvitedEmail: string;
  }): Promise<AxiosResponse<string>> =>
    requests.put("/promoCodes/usersignup", user),
  /**
   * Unused code but relevant for the project
   */
  /* 
  reception: async (code: string): Promise<AxiosResponse<any>> =>
    requests.post(`QRCodes/${code}`, ""), 
  */
};

export default PromoCodesAPI;