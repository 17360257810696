import { AxiosResponse } from "axios";
import { requests } from "./";
import {
  Vehicle,
  AppoitnmentInformation,
  VehicleDashboard,
} from "../../models";

const VehicleAPI = {
  get: (): Promise<AxiosResponse<Vehicle[]>> => requests.get("/vehicles"),
  getById: (id: string): Promise<AxiosResponse<Vehicle>> =>
    requests.get(`/vehicles/byId/${id}`),
  add: (vehicle: Vehicle): Promise<AxiosResponse<Vehicle>> =>
    requests.post("/vehicles", vehicle),
  update: (
    vehicle: Vehicle,
    oldLicensePlate: string
  ): Promise<AxiosResponse<Vehicle>> =>
    requests.put(`/vehicles/byId/${oldLicensePlate}`, vehicle),
  delete: (id: string) => requests.del(`/vehicles/byId/${id}`),
  setIsMain: (
    id: string,
    isMainVehicle: boolean
  ): Promise<AxiosResponse<Vehicle>> =>
    requests.patch(`/vehicles/byId/${id}`, [
      {
        path: "/IsMainVehicle",
        op: "replace",
        value: isMainVehicle,
      },
    ]),
  getVehiclesInProgress: (
    statusCodes: string,
    pageSize: number,
    page: number
  ): Promise<AxiosResponse<VehicleDashboard>> =>
    requests.get(
      `/vehicles/vehiclesInProgress?basicInfo=true${
        !statusCodes ? "" : `statusCodes=${statusCodes}`
      }&pageSize=${pageSize}&page=${page}`
    ),
  getVehiclesInProcess: (
    statusCodes: string
  ): Promise<AxiosResponse<Vehicle[]>> =>
    requests.get(
      `/vehicles/vehiclesInProcess${
        !statusCodes ? "" : `?statusCodes=${statusCodes}`
      }`
    ),
  getVehiclePastAppointments: (
    id: number
  ): Promise<AxiosResponse<AppoitnmentInformation[]>> =>
    requests.get(`/vehicles/${id}/appointments`),
  getActiveAppointments: (id: number): Promise<AxiosResponse<number>> =>
    requests.get(`/vehicles/activeAppointments?endConsumerVehicleId=${id}`),
  getAppointments: ({
    statusCodes,
    endConsumerVehicleId,
    pageSize = 15,
    page = 0,
    viewHidden,
  }: {
    statusCodes?: string;
    endConsumerVehicleId?: number;
    pageSize?: number;
    page?: number;
    viewHidden?: boolean;
  }): Promise<AxiosResponse<VehicleDashboard>> =>
    requests.get(
      `/vehicles/vehiclesInProgress?basicInfo=true&pageSize=${pageSize}&page=${page}${
        !!statusCodes ? `&statusCodes=${statusCodes}` : ""
      }${
        !!endConsumerVehicleId
          ? `&endConsumerVehicleId=${endConsumerVehicleId}`
          : ""
      }&Hidden=${viewHidden}
      `
    ),

  getAppointmentInfo: ({
    WorkshopAppointmentId = "",
  }: {
    WorkshopAppointmentId?: string;
  }): Promise<AxiosResponse<any>> =>
    requests.get(
      `/vehicles/vehiclesInProgress?basicInfo=false&WorkshopAppointmentId=${WorkshopAppointmentId}`
    ),
  getByAppointmentStatus: (
    statusCodes: string
  ): Promise<AxiosResponse<Vehicle[]>> =>
    requests.get(
      `vehicles/vehiclesByAppointmentStatus?statusCodes=${statusCodes}`
    ),
};

export default VehicleAPI;
