import { AxiosResponse } from "axios";
import { requests } from "./";

const RatingAPI = {
  addByAppointment: (
    feedbackMessage: string,
    rating: any[]
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/endConsumerFeedback`, {
      Message: feedbackMessage,
      EndConsumerFeedbacks: rating,
    }),
  getCategoryReports: (): Promise<AxiosResponse<any>> =>
    requests.get(`/catalogs/EndConsumerFeedbackReportReason`),
  getFeedbackByAppointment: (
    appointmentId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`/endConsumerFeedback/${appointmentId}`),
  addComment: (
    feedbackMessage: string,
    workshopAppId: number | string
  ): Promise<AxiosResponse<any>> =>
    requests.post(`/endConsumerFeedback/${workshopAppId}/Message`, {
      Message: feedbackMessage,
    }),
  putReport: (id: string, code: string): Promise<AxiosResponse<any>> =>
    requests.put(
      `/endConsumerFeedback/Messages/${id}?ReportReasonCode=${code}`,
      {}
    ),
  getEndConsumerFeedbackByUserId: (
    userId: string,
    appointmentId?: string = ""
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `/endConsumerFeedback/UserFeedbacks/${userId}${
        appointmentId ? `?WorkshopApptId=${appointmentId}` : ""
      }`
    ),

  getWorkshopVisibleFeedbackByWorkshopId: (
    workshopId: string | undefined,
    page: number,
    pageSize: number | undefined,
    sortDesc?: boolean,
    sortCriteria?: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `endConsumerFeedback/WorkshopRatingVisible/${workshopId}?page=${
        page ? page : 1
      }&pageSize=${pageSize ? pageSize : 0}${
        sortDesc !== undefined ? `&sortDesc=${sortDesc}` : ""
      }${sortCriteria !== undefined ? `&sortCriteria=${sortCriteria}` : ""}&hideReported=${false}`
    ),

  getWorkshopTags: (
    workshopId: string | undefined
  ): Promise<AxiosResponse<any>> =>
    requests.get(`endConsumerFeedback/${workshopId}/categories/count`),

  getNotifications: (): Promise<AxiosResponse<any[]>> =>
    requests.get(`/appointments`, { needFeedback: true }),
  /**
   * Unused code but relevant for the project
   */
  /* 
  getWorkshopFeedbackSummaryByWorkshopId: (
    workshopId: string
  ): Promise<AxiosResponse<any>> =>
    requests.get(`endConsumerFeedback/WorkshopRatingSummary/${workshopId}`), 
  getWorkshopRatingsByWorkshopId: (
    workshopId: string,
    page: number,
    pageSize: number | undefined
  ): Promise<AxiosResponse<any>> =>
    requests.get(
      `endConsumerFeedback/WorkshopRating/${workshopId}?page=${
        page ? page : 1
      }&pageSize=${pageSize ? pageSize : 0}`
    ),
  */
};

export default RatingAPI;
