import { AxiosResponse } from "axios";
import { requests } from "./";

type SasTokenStorage = {
  sasToken: string;
  expire: number;
};

const ConfigurationsAPI = {
  getSasToken: async (): Promise<{ data: string }> => {
    if (sessionStorage.getItem("sasToken")) {
      const sasTokenObj: SasTokenStorage = JSON.parse(
        sessionStorage.getItem("sasToken")!
      );
      if (sasTokenObj.expire > Date.now()) {
        return new Promise((resolve) =>
          resolve({ data: sasTokenObj.sasToken })
        );
      }
    }
    const request = await requests.get("configurations/sasToken");

    if (request.status >= 200 && request.status < 300) {
      sessionStorage.setItem(
        "sasToken",
        JSON.stringify({
          sasToken: request.data,
          expire: new URLSearchParams(request.data).has("se")
            ? new Date(new URLSearchParams(request.data).get("se")!).valueOf()
            : Date.now() + 300000,
        })
      );
      return new Promise((resolve) =>
        resolve({
          data: request.data,
        })
      );
    }
    return new Promise((resolve) => resolve(request));
  },
  getSasTokenNonRegistered: async (): Promise<AxiosResponse<string>> => {
    if (sessionStorage.getItem("sasToken")) {
      const sasTokenObj: SasTokenStorage = JSON.parse(
        sessionStorage.getItem("sasToken")!
      );
      if (sasTokenObj.expire > Date.now()) {
        return new Promise((resolve) =>
          resolve(
            // @ts-ignore
            { data: sasTokenObj.sasToken }
          )
        );
      }
    }
    const request = await requests.get(`/configurations/sasTokenNonRegistered`);

    if (request.status >= 200 && request.status < 300) {
      sessionStorage.setItem(
        "sasToken",
        JSON.stringify({
          sasToken: request.data,
          expire: new URLSearchParams(request.data).has("se")
            ? new Date(new URLSearchParams(request.data).get("se")!).valueOf()
            : Date.now() + 300000,
        })
      );

      return new Promise((resolve) =>
        resolve(
          // @ts-ignore
          { data: request.data,}
        )
      );
    }
    return new Promise((resolve) => resolve(request));
  },
  getGoogleRecapcha: async (): Promise<AxiosResponse<boolean>> => requests.get(`/configurations/sasTokenNonRegistered`)
};

export default ConfigurationsAPI;
