import { AxiosResponse } from "axios";
import { requests } from "./";
import { User } from "../../models";

const UserAPI = {
  me: async (): Promise<AxiosResponse<User>> => requests.get("users/me"),
  update: async (user: User): Promise<AxiosResponse<User>> =>
    requests.put(`users/${user.id}`, user),
  clearNotifications: async (
    payload: Array<any>,
  ): Promise<AxiosResponse<User>> =>
    requests.put(`notifications/bellNotifications/All`, payload),
  markAllAsRead: async (payload: Array<any>): Promise<AxiosResponse<User>> =>
    requests.put(
      `notifications/bellNotifications/All?isNewField=true`,
      payload,
    ),
  updatePassword: async (password: string) =>
    requests.put("/b2c/users/me?isEndConsumer=true", { password: password }),
  delete: async (id: string, fullDelete: boolean, reason: string) =>
    requests.del(`users/${id}?fullDelete=${fullDelete}&deleteReason=${reason}`),
  checkUser: async (email: string) => requests.get(`users/isB2CUser?email=${email}`),
  userSourceByEmail: async (email: string) =>
    requests.get(`user/source?userEmail=${email}`, { email }),
  isSocialNetworkUser: async (email: string) =>
    requests.get("users/isSocialNetworkUser", { email: email }),
  getUserNotifications: async (userId: string | undefined) =>
    requests.get("notifications/bellNotifications", { userId: userId }),
  setUserNotificationAsRead: async (notificationId: number | undefined) =>
    requests.put(
      `notifications/bellNotifications?NotificationId=${notificationId}`,
      { notificationId },
    ),
  signUp: async (
    data: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    },
    isEndConsumer: boolean,
  ) => requests.post(`b2c/users?isEndConsumer=${isEndConsumer}`, data),

  /** Merged from UsersAPI from shared */
  getUserStatus: (email: string) => {
    return requests.get(`ConfirmUser?userEmail=${email}`);
  },
  updateUserStatus: (code: string, clientSecret: string) => {
    const encoded_cs = encodeURIComponent(clientSecret);
    return requests.post(`ConfirmUser/${code}`, {
      client_secret: encoded_cs,
    });
  },
  resendVerificationEmail: (email: string, password: string) => {
    const encoded_pass = encodeURIComponent(password);
    return requests.get(
      `ConfirmUser/email?userEmail=${email}&client_secret=${encoded_pass}`,
    );
  },
};

export default UserAPI;

/* 
export const erase = (userId: string | number) => {
  return requests.delete(`users/${userId}`);
}; 
*/
